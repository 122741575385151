import React, { useState }  from 'react';

/*
import lagarto from './images/lagarto.jpg';
import spock from   './images/spock.jpg';
import tijera from  './images/tijera.jpg';
import papel from   './images/papel.png';
import piedra from  './images/piedra.jpg';
*/
import imgInicial from  './images/init.jpg';
import './App.css';

//Json con mano, rival que le gana y accion
const manoJson = [
  {"mano":"lagarto",
   "src": '/images/lagarto.jpg',
   "gana": [
      {"accion":"envena","rival":"spock"},
      {"accion":"devora","rival":"papel"}
    ]},
  {"mano":"spock",
   "src":'/images/spock.jpg',
   "gana": [
      {"accion":"rompe","rival":"tijera"},
      {"accion":"vaporiza","rival":"piedra"}
    ]},
  {"mano":"tijera",
   "src": "/images/tijera.jpg",
   "gana": [
      {"accion":"corta","rival":"papel"},
      {"accion":"decapita","rival":"lagarto"}
    ]},
  {"mano":"papel",
   "src":"/images/papel.png",
   "gana": [
      {"accion":"envuelve","rival":"piedra"},
      {"accion":"desautoriza","rival":"spock"}
    ]},
  {"mano":"piedra",
   "src":"/images/piedra.jpg",
   "gana": [
      {"accion":"aplasta","rival":"tijera"},
      {"accion":"aplasta","rival":"lagarto"}
    ]},
  ];

function App() {

  const [contador, setContador] = useState(-1); //Cantidad de partidas jugadas.
  const [contGanaCPU, setContGanaCPU] = useState(0); //Cant. Part. Ganadas CPU
  const [contGanaJugador, setContGanaJugador] = useState(0); //Cant. Part. Ganadas Jugador
  const [mensaje, setMensaje] = useState("");    //Mensaje de resultado Partida.

  
  const [resultadoJugadorClass, setResultadoJugadorClass] = useState(""); //Mano del jugador
  const [resultadoCPUClass, setResultadoCPUClass] = useState(""); //Mano del jugador
  const [manoJugador, setManoJugador] = useState(""); //Mano del jugador
  const [manoCPU, setManoCPU] = useState("");   //Mano del CPU

  //Función Jugar
  const jugar=(e)=>{
    let nroManoCPU = Math.floor(Math.random()*manoJson.length); //Mano random tomada del JSON
    if (contador >= 0) { //Si contador es 0 no entra, (Load)     
      //Filtro del Json la mano seleccionada del jugador
      let data_filter_jugador = manoJson.filter( element => element.mano === e.currentTarget.id);
      //Busco que entre los rivales que le gana esta la CPU
      let resultadoJugador = data_filter_jugador[0].gana.filter( mansad => mansad.rival === manoJson[nroManoCPU].mano);

      //Filtro del Json la mano random del CPU
      let data_filter_cpu = manoJson.filter( element => element.mano === manoJson[nroManoCPU].mano);
      //Busco que entre los rivales que le gana esta el seleccionado por el Jugador
      let resultadoCPU = data_filter_cpu[0].gana.filter( mansad => mansad.rival === e.currentTarget.id);

      //Asigno a los hooks el json del jugador y CPU.
      setManoJugador(data_filter_jugador[0]);
      setManoCPU(manoJson[nroManoCPU])

      //Si el resultado es mayor a cero indica que hubo un rival que le gana
      //Si ganan o pierden ambos es empate
      //Armo los mensajes
      if (resultadoJugador.length > 0) {
        setContGanaJugador(contGanaJugador + 1);
        setResultadoJugadorClass("img imgGanador");
        setResultadoCPUClass("img imgPerdedor");
        setMensaje('Jugador Gana: ' + e.currentTarget.id + ' ' + resultadoJugador[0].accion + ' ' + manoJson[nroManoCPU].mano);
      } else if (resultadoCPU.length > 0) {
        setContGanaCPU(contGanaCPU + 1);
        setMensaje('CPU Gana: ' +  manoJson[nroManoCPU].mano + ' ' + resultadoCPU[0].accion + ' ' + resultadoCPU[0].rival) ;
        setResultadoJugadorClass("img imgPerdedor");
        setResultadoCPUClass("img imgGanador");
      } else {
        setMensaje('Empate');
        setResultadoJugadorClass("img imgEmpate");
        setResultadoCPUClass("img imgEmpate");
      }
    }  
    setContador(contador+1);
  } 
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <main>
        <div className="resultadoDiv">           
          <figure>          
              <img id={manoJugador.mano} src={manoJugador.src ? manoJugador.src : imgInicial} alt={manoJugador.src} className={resultadoJugadorClass}/>
              <figcaption>Jugador Ganó: { contGanaJugador }</figcaption>
          </figure>              
          <figure>
              <img id={manoCPU.mano} src={manoCPU.src ? manoCPU.src  : imgInicial} alt={manoJugador.src} alt={manoCPU.src} className={resultadoCPUClass}/>
              <figcaption>CPU Ganó:{contGanaCPU}</figcaption>
          </figure>
          <div className="resultadoMsj">
            <p>{ mensaje }</p>
          </div>
        </div>
        <div className="galeriaImagenes">
            {manoJson.map((mano, i) => 
              <img onClick={jugar} id={mano.mano} src={mano.src} alt={mano.src} />
            )}
        </div> 
      </main>
    </div>
  );
}

export default App;
